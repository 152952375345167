import type { RouteLocationNormalized } from 'vue-router'
import { onUnmounted } from 'vue'

export function useOnRouteLeave(
  callback: (to: RouteLocationNormalized) => void,
  beforeUnmounting: boolean = false,
) {
  const isLeavingTo = ref<RouteLocationNormalized | null>(null)
  const isUnmounted = ref<boolean>(false)
  ;({
    onBeforeUnmount,
    onUnmounted,
  })[beforeUnmounting ? 'onBeforeUnmount' : 'onUnmounted'](() => {
    isUnmounted.value = true
    const toRoute = isLeavingTo.value
    if (!toRoute) {
      return
    }
    callback(toRoute)
    isLeavingTo.value = null // cleanup
  })
  onBeforeRouteLeave((to) => {
    if (isUnmounted.value) {
      // unmounting could happen before route leave event
      callback(to)
      return
    }
    isLeavingTo.value = to
  })
}
